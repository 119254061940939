import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faLeaf, faSyringe } from '@fortawesome/free-solid-svg-icons';

import Link from './Link';
import { P } from '../components/common/P.style';

import pancIcon from '../assets/img/panc-icon.svg';
import meIcon from '../assets/img/me-icon.svg';
import resIcon from '../assets/img/res-icon.svg';
import calIcon from '../assets/img/cal-icon.svg';
import endoIcon from '../assets/img/endo-icon.svg';
import syringeIcon from '../assets/img/syringe.png';
import onlineIcon from '../assets/img/online.svg';
import covidIcon from '../assets/img/covid-icon_menu.svg';
import reserveIcon from '../assets/img/web-reserve.png';
import travelIcon from '../assets/img/travel.png';
import feverIcon from '../assets/img/fever.png';
import healthIcon from '../assets/img/health.png';

const Title = styled.span`
  font-size: ${({ size }) => (size !== 'sm' && 24) || 14}px;
  color: #666666;
`;

const IconImg = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 10px;
`;

const Item = ({ icon, title, link, size, columnRight, reserve }) => (
  <Link to={link}>
    <Flex
      center={size !== 'sm'}
      css={`
        width: ${(size === 'sm' && ((columnRight && 170) || 150)) || 380}px;
        height: 60px;
        margin: ${(size === 'sm' && '10px') || '10px'};
        border: ${(size !== 'sm' && 'solid 1px #707070') || 0};
        background-color: #ffffff;
        padding-left: ${(columnRight && '30px') || 0};
        font-family: 'M PLUS Rounded 1c', TsukuARdGothic-Regular;
      `}
    >
      <Flex center>{icon}</Flex>
      <Flex center>
        <Title size={size}>{title}</Title>
      </Flex>
      <Flex center>{reserve}</Flex>
    </Flex>
  </Link>
);

const Line = ({
  leftIcon,
  leftTile,
  leftLink,
  leftReserveIcon,
  rightIcon,
  rightTitle,
  rightLink,
  size,
  rightReserveIcon,
}) => (
  <Flex justifyCenter>
    <FlexItem>
      <Item
        icon={leftIcon}
        title={leftTile}
        link={leftLink}
        size={size}
        reserve={leftReserveIcon}
      />
    </FlexItem>
    <FlexItem>
      <Item
        icon={rightIcon}
        title={rightTitle}
        link={rightLink}
        size={size}
        reserve={rightReserveIcon}
      />
    </FlexItem>
  </Flex>
);

const LineOne = ({ leftIcon, leftTile, leftLink, size }) => (
  <Flex justifyCenter>
    <FlexItem>
      <Item icon={leftIcon} title={leftTile} link={leftLink} size={size} />
    </FlexItem>
  </Flex>
);

const MedicalGuidanceArea = ({ size }) => (
  <div>
    <Line
      size={size}
      leftIcon={<IconImg src={covidIcon} />}
      leftTile="新型コロナ後遺症外来"
      leftLink="/covid19"
      rightIcon={<IconImg src={healthIcon} />}
      rightTitle="健康診断"
      rightLink="https://www.hirahata.net/"
    />
    <Line
      size={size}
      leftIcon={
        <FontAwesomeIcon
          icon={faStethoscope}
          style={{ fontSize: 23, marginRight: 10 }}
          color="#fbb03b"
        />
      }
      leftTile="糖尿病・生活習慣病"
      leftLink="/internal"
      rightIcon={<IconImg src={pancIcon} />}
      rightTitle="膵臓の弱い方へ"
      rightLink="/pancreas"
    />
    <Line
      size={size}
      leftIcon={<IconImg src={endoIcon} />}
      leftTile="内視鏡検査　"
      leftReserveIcon={<IconImg src={reserveIcon} />}
      leftLink="/endoscopy"
      rightIcon={<IconImg src={meIcon} />}
      rightTitle="その他の検査"
      rightLink="/exams"
    />
    <Line
      size={size}
      leftIcon={<IconImg src={syringeIcon} />}
      leftTile="予防接種"
      leftLink="/vaccine"
      rightIcon={<IconImg src={travelIcon} />}
      rightTitle="トラベル・海外渡航前検査"
      rightLink="/covid-pcr"
      rightReserveIcon={<IconImg src={reserveIcon} />}
    />
    <Line
      size={size}
      leftIcon={<IconImg src={feverIcon} />}
      leftTile="発熱外来（予約制）"
      leftLink="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=fbeJwzMTI3MrUAAARMAT0"
      leftReserveIcon={<IconImg src={reserveIcon} />}
      rightIcon={
        <FontAwesomeIcon icon={faLeaf} style={{ fontSize: 23, marginRight: 10 }} color="#fbb03b" />
      }
      rightTitle="漢方外来"
      rightLink="/kampo"
    />
    <LineOne
      size={size}
      leftIcon={<IconImg src={onlineIcon} />}
      leftTile="オンライン診療"
      leftLink="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya"
    />

    <LineOne
      size={size}
      // leftIcon={<IconImg src={onlineIcon} />}
      leftTile="各種予約の空き状況※"
      leftLink="https://wait.hirahata-clinic.or.jp/yoyakujoukyou.html"
    />

    <div
      css={`
        text-align: center;
      `}
    >
      <P>※各ボタンをタップすると空き状況のカレンダーが表示されます。</P>
    </div>

    {/* 旧メニュー
    <Line
      size={size}
      leftIcon={<IconImg src={covidIcon} />}
      leftTile="新型コロナ後遺症外来"
      leftLink="/covid19"
      rightIcon={<IconImg src={onlineIcon} />}
      rightTitle="オンライン診療"
      rightLink="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya"
    />
    <Line
      size={size}
      leftIcon={<IconImg src={pancIcon} />}
      leftTile="膵臓の弱い方へ"
      leftLink="/pancreas"
      rightIcon={<IconImg src={endoIcon} />}
      rightTitle="内視鏡"
      rightLink="/endoscopy"
    />
    <Line
      size={size}
      leftIcon={
        <FontAwesomeIcon
          icon={faStethoscope}
          style={{ fontSize: 23, marginRight: 10 }}
          color="#fbb03b"
        />
      }
      leftTile="糖尿病・生活習慣病"
      leftLink="/internal"
      rightIcon={
        <FontAwesomeIcon icon={faLeaf} style={{ fontSize: 23, marginRight: 10 }} color="#fbb03b" />
      }
      rightTitle="漢方外来"
      rightLink="/kampo"
    />

    <Line
      size={size}
      leftIcon={<IconImg src={meIcon} />}
      leftTile="各種検査"
      leftLink="/exams"
      rightIcon={
        <FontAwesomeIcon
          icon={faSyringe}
          style={{ fontSize: 23, marginRight: 10 }}
          color="#fbb03b"
        />
      }
      rightTitle="予防接種"
      rightLink="/vaccine"
    />
     */}
  </div>
);

export default MedicalGuidanceArea;

// 一旦非表示
// 一般内科右側にあったもの
// rightIcon={<IconImg src={resIcon} />}
// rightTitle="呼吸器内科"
// rightLink="/respiratory"
// 漢方外来右側にあったもの
// rightIcon={<IconImg src={calIcon} />}
// rightTitle="循環器内科"
// rightLink="/cardio"
